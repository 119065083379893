import $ from 'jquery'

$(document).ready( initTestimonialsSlider )

function initTestimonialsSlider(){
  const sliderWrapper = $('.js-testimonials-slider');
  const sliderWrapperSmall = $('.js-testimonials-slider-small');

  if (sliderWrapper.length) {
    sliderWrapper.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      fade: true,
      dots: true,
    });
  }

  if (sliderWrapperSmall.length) {
    sliderWrapperSmall.slick({
      slidesToShow: $('.home').lenght ? 4 : 2,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      fade: false,
      dots: true,
      nextArrow: '<button type="button" class="slick-next"><svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.80005 7C1.24776 7 0.800049 7.44772 0.800049 8C0.800049 8.55228 1.24776 9 1.80005 9L1.80005 7ZM20.907 8.70711C21.2975 8.31658 21.2975 7.68342 20.907 7.29289L14.543 0.928933C14.1525 0.538409 13.5193 0.538409 13.1288 0.928933C12.7383 1.31946 12.7383 1.95262 13.1288 2.34315L18.7857 8L13.1288 13.6569C12.7383 14.0474 12.7383 14.6805 13.1288 15.0711C13.5193 15.4616 14.1525 15.4616 14.543 15.0711L20.907 8.70711ZM1.80005 9L20.1999 9L20.1999 7L1.80005 7L1.80005 9Z" fill="black" /></svg></button>',
      prevArrow: '<button type="button" class="slick-prev"><svg width="29" height="54" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 2L3 27L27 52" stroke="#425050" stroke-width="4"/></svg></button>',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  }
}
