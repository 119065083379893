import $ from 'jquery'

export function featuredPostsSliderBB($slider) {
  $slider.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: false,
    nextArrow: '<button type="button" class="slick-next next-btn icon"><svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="35.75" cy="35.1511" r="35" fill="#C0D76D" /><path d="M25.0176 34.1511C24.4653 34.1511 24.0176 34.5988 24.0176 35.1511C24.0176 35.7034 24.4653 36.1511 25.0176 36.1511L25.0176 34.1511ZM47.1911 35.8582C47.5817 35.4677 47.5817 34.8345 47.1911 34.444L40.8272 28.0801C40.4366 27.6895 39.8035 27.6895 39.413 28.0801C39.0224 28.4706 39.0224 29.1037 39.413 29.4943L45.0698 35.1511L39.413 40.808C39.0224 41.1985 39.0224 41.8317 39.413 42.2222C39.8035 42.6127 40.4366 42.6127 40.8272 42.2222L47.1911 35.8582ZM25.0176 36.1511L46.484 36.1511L46.484 34.1511L25.0176 34.1511L25.0176 36.1511Z" fill="black" /></svg></button>',
      prevArrow: '<button type="button" class="slick-prev prev-btn icon"><svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="35.25" cy="35.1511" r="35" transform="rotate(-180 35.25 35.1511)" fill="#C0D76D" /><path d="M45.9824 36.1511C46.5347 36.1511 46.9824 35.7034 46.9824 35.1511C46.9824 34.5988 46.5347 34.1511 45.9824 34.1511L45.9824 36.1511ZM23.8089 34.444C23.4183 34.8345 23.4183 35.4677 23.8089 35.8582L30.1728 42.2222C30.5633 42.6127 31.1965 42.6127 31.587 42.2222C31.9776 41.8317 31.9776 41.1985 31.587 40.808L25.9302 35.1511L31.587 29.4943C31.9776 29.1037 31.9776 28.4706 31.587 28.0801C31.1965 27.6895 30.5634 27.6895 30.1728 28.0801L23.8089 34.444ZM45.9824 34.1511L24.516 34.1511L24.516 36.1511L45.9824 36.1511L45.9824 34.1511Z" fill="black" /></svg></button>',
    responsive: [
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
  });
}

export function initFeaturedPostsSlider() {
    const sliderWrapper = $('.js-featured-posts');
    if (sliderWrapper.length) {
        sliderWrapper.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }
}

export default {featuredPostsSliderBB, initFeaturedPostsSlider}
