import $ from 'jquery'
$(document).ready(initImageGallerySlider)

function initImageGallerySlider() {
    const galleryWrapper = $('.js-image-gallery');
    if (galleryWrapper.length) {
        galleryWrapper.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
        });
    }
}
