import $ from 'jquery'
import { featuredPostsSliderBB } from '../post/post-slider'

const $doc = $(document)

function toggleAccordionItem() {
  let el = $(this);
  if ( el.parent().parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  el.parent().next().slideToggle('fast');
  el.parent().parent().toggleClass('active');

  // Here we need to refresh the slider because it will be hidden inside the accordion onload
  const $featuredPostsChildren = el.parent().parent().find('.js-bb-featured-posts');
  if ( $featuredPostsChildren.length && $featuredPostsChildren.find('.grid__column').length > 3) {
    $featuredPostsChildren.slick('refresh')
  }
}

$doc.on('click', '.js-accordion-item button', toggleAccordionItem )
