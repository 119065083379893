import $ from 'jquery'

$(document).on('DOMContentLoaded', function() {
    if ($('.hero__media video').length) {
        var video = $('.hero__media video').get(0);
        if (video.paused) { 
            video.play();  
            $('.hero__media__pause').show();
            $('.hero__media__play').hide();
        } 
    }
})
$(document).on('click', '.hero__media__link', function (e) {
    var video = $('.hero__media video').get(0);
    if (video.paused === false) {
        video.pause();
        $('.hero__media__link span').text('Play');
        $('.hero__media__pause').hide();
        $('.hero__media__play').show();
    } else {
        video.play();
        $('.hero__media__link span').text('Pause');
        $('.hero__media__pause').show();
        $('.hero__media__play').hide();
    }
    return false;
});