import $ from 'jquery'
import { initFeaturedPostsSlider } from './post-slider'
import { featuredPostsSliderBB } from './post-slider'
import videoPops from './video-popup'

$(document).ready(()=> {
  if ($(".js-featured-posts .grid__column").length > 3){
    initFeaturedPostsSlider()
  }
  if ($(".js-bb-featured-posts").length){
    $(".js-bb-featured-posts").each(function() {
      if ($(this).find(".grid__column").length > 3) {
        featuredPostsSliderBB($(this))
      }
    })
  }
  if ($('.js-video-post').length) {
    videoPops()
  }
})
